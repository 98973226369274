import React from "react"
import { graphql } from "gatsby";
import { Layout } from "@components/Layout";
import { mapToSEO, SEO } from "@components/SEO";
import { Grid } from "semantic-ui-react";
import { mapToCheckoutSelectionCard } from "@mappers/Checkout/CheckoutSelectionCard";
import OrderSummary from "@components/OrderSummary/OrderSummary";
import theme from "@styles/theme";
import { Record } from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from "redux"
import { currentCheckoutSelector } from "@redux/selectors/checkoutSelector";
import { updateGiftingType, updateIsGifting } from "@redux/actions/checkout";
import { currentBasketSelector } from "@redux/selectors/basketSelector";
import { isMobile } from "@utils/Helpers";
import { NavHeader } from "@components/Mobile/NavHeader/NavHeader";
import MobileGenericBannerContainer from "@components/Mobile/GenericBannerContainer/GenericBannerContainer";
import MobileGiftingSelection from "@components/Mobile/GiftingSelection/GiftingSelection";

const GiftingSelectionPageContainer = (props:any) => {

    const {elements} = props.data.allKontentItemPlateOwnerSelectionPage.nodes[0];
    const apiUrl = props.data.site.siteMetadata.apiUrl;
    const seo = mapToSEO(elements);
    const yesCard = mapToCheckoutSelectionCard(elements.plate_owner_card.linked_items[0].elements);
    const noCard = mapToCheckoutSelectionCard(elements.gifting_card.linked_items[0].elements);

    if(isMobile() === undefined){
      return null;
    }

    return (
    <Layout version="simple" currentStep={3} location={props.location}>
        <SEO {...seo} />
        <MobileGenericBannerContainer
          padding={{
            mobile: {
                top: 20,
                bottom: 40
            },
            desktop: {
                top: 40,
                bottom: 60
            }
          }}
          backgroundColor={isMobile() ? theme.mobileTheme.bgColor : theme.brand.colors.beige}>
          <Grid stackable reversed='mobile tablet vertically' stretched>
            <Grid.Column mobile={16} tablet={16} computer={10}>
              <MobileGiftingSelection
                heading={elements.heading.value}
                subHeading={elements.sub_heading.value}
                body={elements.body.value}
                yesCard={yesCard}
                updateIsGifting={props.updateIsGifting}
                updateGiftingType={props.updateGiftingType}
                checkout={props.checkoutState}
                noCard={noCard} /> 
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={5} floated='right'>
              {isMobile() ?
              <NavHeader link="/plate-owner-selection" info="Plate Owner"/>:
              <OrderSummary isExpanded={false} isLayby={false} showSurcharge={false} basket={props.basket} apiUrl={apiUrl} giftType={props.checkoutState.giftingType}/>}
            </Grid.Column>
          </Grid>
        </MobileGenericBannerContainer>
    </Layout>);

}


const mapStateToProps = (state: Record<any>) => {
  const checkout = currentCheckoutSelector(state).toJS();
  const basket = currentBasketSelector(state).toJS();
  return {
    checkoutState: checkout,
    basket: basket
  };
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateIsGifting(isGifting:boolean){
    dispatch(updateIsGifting(isGifting));
},
  updateGiftingType(giftingType: number) {
    dispatch(updateGiftingType(giftingType));
  }
})

const GiftingSelectionPage = connect(mapStateToProps,mapDispatchToProps)(GiftingSelectionPageContainer)


export const query = graphql`
{
  site {
    siteMetadata {
      apiUrl
    }
  }
  allKontentItemPlateOwnerSelectionPage(filter: {elements: {sub_heading: {value: {eq: "Is this a gift?"}}}}) {
    nodes {
      elements {
        body {
          value
        }
        heading {
          value
        }
        seo__nofollow {
          value {
            codename
            name
          }
        }
        seo__noindex {
          value {
            codename
            name
          }
        }
        seo__page_description {
          value
        }
        seo__page_title {
          value
        }
        url{
          value
        }
        sub_heading {
          value
        }
        plate_owner_card {
          linked_items {
            ... on KontentItemCheckoutSelectionCard {
              id
              elements {
                body {
                  value
                }
                heading {
                  value
                }
              }
            }
          }
        }
        gifting_card {
          linked_items {
            ... on KontentItemCheckoutSelectionCard {
              id
              elements {
                body {
                  value
                }
                heading {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
}
  `

export default GiftingSelectionPage;